<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="3"
      class="py-0"
    >
      <label>{{ label }}</label>
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="dateSelected"
        persistent
        width="290px"
      >
        <template #activator="{ on }">
          <v-text-field
            v-model="dateSelected"
            prepend-icon="event"
            :rules="[validDate]"
            readonly
            class="py-0"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="dateSelected"
          :min="minDay"
          :disabled="disabled"
        >
          <v-spacer />
          <app-button
            secondary
            cancel
            @click="modal = false"
          >
            Cancel
          </app-button>
          <app-button @click="save">
            OK
          </app-button>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'DatePicker',
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    date: {
      type: String,
      default: null
    },
    min: {
      type: String,
      default: null
    },
    validDate: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      modal: false,
      dateSelected: null,
      minDay: null,
    };
  },
  created() {
    if (this.date) {
      this.dateSelected = this.date
        ? new Date(this.date).toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10);
    }
    if (this.min) {
      this.minDay = new Date(this.min).toISOString().substr(0, 10);
    }
  },
  methods: {
    save() {
      this.modal = false;
      this.$emit('dateSelected', this.dateSelected);
      this.$refs.dialog.save(this.dateSelected);
    }
  }
};
</script>

<style scoped>

</style>
