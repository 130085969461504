<template>
  <v-container class="campaign-settings-container">
    <default-custom-base
      v-if="translations"
      :title="title"
      :is-default="isDefault"
      @isDefaultSelected="isDefault = $event"
      @save="save"
    >
      <div
        v-for="content in customContent"
        :key="`campaign-${content.language}`"
        class="mt-2 py-2"
      >
        <label class="d-block mb-3 font-weight-bold">
          {{ getLanguageTitle(content.language) }}
        </label>
        <template v-if="isDefault">
          <label class="d-block body-2 font-weight-medium mb-1">Title</label>
          <label class="body-2">{{ translations.title[content.language] }}</label>
        </template>
        <template v-else>
          <v-text-field
            ref="title"
            v-model="content.title"
            label="Title*"
            clearable
            placeholder="Enter a title"
            class="mt-0 campaign-title"
            :rules="[rules.required]"
            required
          />
        </template>
        <template v-if="isDefault">
          <label class="d-block body-2 font-weight-medium mb-1 mt-4">Description</label>
          <label class="body-2">{{ translations.description[content.language] }}</label>
          <v-divider class="mt-5" />
        </template>
        <template v-else>
          <v-textarea
            ref="description"
            v-model="content.text"
            label="Description*"
            auto-grow
            clearable
            placeholder="Enter a description..."
            class="mt-2 campaign-description"
            :rows="1"
            :rules="[rules.required]"
            required
          />
        </template>
      </div>
    </default-custom-base>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { ContentService } from '@kickbox/common-admin';
import LanguageService from '@/services/languageService';
import campaignService from '@/services/campaignService';
import DefaultCustomBase from '@/components/widgets/DefaultCustomBase';

export default {
  components: {
    DefaultCustomBase
  },
  data() {
    return {
      isDefault: true,
      availableLanguages: [],
      translations: null,
      customContent: [],
      title: 'Define the title and description that will appear in the portal campaign page',
      rules: {
        required: (value) => !!value || 'The field is required.'
      }
    };
  },
  computed: {
    ...mapGetters({
      company: 'company',
      storeCustomContent: 'customContent'
    }),
    companyLanguage() {
      return this.company.language;
    }
  },
  async created() {
    this.availableLanguages = await LanguageService.getLanguages();
    this.customContent = await ContentService
      .getCampaignContent(this.company.parseObject);
    if (!this.customContent.length) {
      this.customContent = this.addCustomContent();
    }
    this.checkCustomLanguages();
    const isCustomActive = this.customContent.some((cc) => cc.customEnabled);
    this.isDefault = !isCustomActive;
    this.$store.commit('setCustomContent', this.customContent);
    this.translations = await campaignService
      .getCampaignTranslations(this.availableLanguages);
  },
  methods: {
    async save() {
      this.validateContent();
      const invalidContent = this.customContent.some((cc) => !cc.title || !cc.text);
      if (invalidContent && !this.isDefault) {
        return this.$store.dispatch('showSnackBar', { text: 'Please, fill in all the mandatory fields.' });
      }
      const promises = this.customContent.map(async (content) => {
        let updateContent;
        if (this.isDefault) {
          updateContent = {
            ...content,
            default: true,
            customEnabled: false
          };
        } else {
          updateContent = {
            ...content,
            default: false,
            customEnabled: true
          };
        }
        delete updateContent.parseObject;
        const result = await ContentService.manageContent(updateContent, this.storeCustomContent);
        this.$store.commit('updateCustomContent', result.content);
      });
      await Promise.all(promises);
      return this.$store.dispatch('showSnackBar', { text: 'The campaigns settings have been saved.' });
    },
    addCustomContent() {
      return this.availableLanguages.map((language) => ({
        title: '',
        language: language.key,
        text: '',
        name: 'campaign_settings',
        company: this.company.parseObject
      }));
    },
    validateContent() {
      ['title', 'description'].forEach((ctrl) => {
        this.$refs[ctrl].forEach((comp) => {
          comp.validate(true);
        });
      });
    },
    getLanguageTitle(key) {
      return (this.availableLanguages.find((l) => l.key === key)).name;
    },
    checkCustomLanguages() {
      this.availableLanguages.forEach((language) => {
        const haveLanguage = this.customContent
          .some((content) => content.language === language.key);
        if (!haveLanguage) {
          this.customContent.push({
            title: '',
            language: language.key,
            text: '',
            name: 'campaign_settings',
            company: this.company.parseObject
          });
        }
      });
      const languages = this.availableLanguages.map((lang) => lang.key);
      this.customContent = this.customContent
        .filter((content) => languages.includes(content.language));
    }
  }
};
</script>

<style lang="scss">
.campaign-settings-container {
  .campaign-title .v-label, .campaign-description .v-label {
    font-size: 18px;
    color: #464646;
    font-weight: 500;
    padding-bottom: 2rem;
    display: inline-block;
    letter-spacing: .02em;
    line-height: 1.25rem;
  }

}
</style>
