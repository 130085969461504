<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        offset-md="1"
        md="9"
        xl="7"
      >
        <v-card class="campaign-container">
          <v-row>
            <v-col
              ref="uploaderContainer"
              cols="12"
              class="image-uploader-container"
            >
              <select-image
                ref="selectImage"
                :initial-image="campaigns && campaign.cover"
                :width="$refs.uploaderContainer && $refs.uploaderContainer.clientWidth - 10"
                :height="170"
                :placeholder-font-size="18"
                :file-size-limit="10485760"
              />
            </v-col>
          </v-row>
          <v-tabs
            v-model="activeTabIndex"
            class="tabs-container"
            background-color="primary"
            dark
            slider-color="white"
          >
            <v-tab
              v-for="lang in enabledLanguages"
              :key="lang"
              :ruid="`tab_${lang}`"
              ripple
            >
              {{ lang }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTabIndex">
            <v-tab-item
              v-for="lang in enabledLanguages"
              :key="lang"
            >
              <div
                v-if="campaign && campaign.content[lang]"
                class="mt-3"
              >
                <v-text-field
                  ref="title"
                  v-model="campaign.content[lang].title"
                  :ruid="`title_${lang}`"
                  :rules="[rules.required]"
                  :disabled="hasSubmitIntention"
                  label="Title*"
                  clearable
                  required
                  placeholder="Enter the campaign title"
                />
                <v-text-field
                  ref="tagline"
                  v-model="campaign.content[lang].tagline"
                  :ruid="`tagline_${lang}`"
                  :rules="[rules.required]"
                  :disabled="hasSubmitIntention"
                  label="Tagline*"
                  clearable
                  required
                  placeholder="Enter the campaign tagline"
                />
                <div v-if="!campaign.defaultLanguage || isDefault(lang)">
                  <label>Video</label>
                  <video-upload
                    :ref="`videoUpload${lang}`"
                    :ruid="`videoUpload_${lang}`"
                    :storage-key="`${storageKey}_${lang}`"
                    :url="campaign.content[lang].video"
                    :disabled="hasSubmitIntention"
                    :object-info="objectInfo"
                    @change="videoChangeEvent($event, lang)"
                    @validate="videoUploadDetails[lang].videoError = $event"
                    @progress="onProgress($event, lang)"
                  />
                  <v-checkbox
                    v-if="campaign.content[lang].video"
                    v-model="campaign.defaultLanguage"
                    v-ruid="`defaultLanguage_${lang}`"
                    :true-value="lang"
                    :false-value="null"
                    label="Make this video default for all languages"
                    @change="makeVideoDefault(lang)"
                  />
                </div>
                <div
                  v-else
                  class="default-video-defined mb-2"
                  @click="gotoDefaultVideo()"
                >
                  <v-icon>info</v-icon>
                  <div class="default-video-label">
                    Default video already defined
                  </div>
                </div>
                <video-display
                  :edit-mode="true"
                  :url="campaign.content[lang].video"
                  :storage-key="getStorageKeyForDisplay(lang)"
                />
                <div class="mb-2">
                  <label
                    :class="{ 'red--text text--lighten-2': showDescriptionValidationHint[lang] }"
                  >
                    Description*
                  </label>
                </div>
                <editor
                  ref="description"
                  :ruid="`description_${lang}`"
                  :required="true"
                  :disabled="hasSubmitIntention"
                  :show-cancel-button="false"
                  :show-submit-button="false"
                  :content="campaign.content[lang].description"
                  @keyUp="campaign.content[lang].description = $event"
                />
                <div class="mt-1">
                  <label class="body-1">* Mandatory field</label>
                </div>
              </div>
            </v-tab-item>
          </v-tabs-items>
          <date-picker
            v-if="startDate"
            class="mt-4"
            label="Start date"
            :date="startDate"
            :disabled="hasSubmitIntention"
            :valid-date="validDate"
            @dateSelected="startDate = $event"
          />
          <v-checkbox
            v-model="setDeadline"
            :disabled="hasSubmitIntention"
            label="Set deadline"
          />
          <date-picker
            v-if="setDeadline"
            :disabled="hasSubmitIntention"
            :date="campaignDeadline"
            :min="startDate"
            @dateSelected="campaignDeadline = $event"
          />
          <v-checkbox
            v-model="setPeople"
            :disabled="hasSubmitIntention"
            label="Enable people section"
          />
          <v-text-field
            v-if="setPeople"
            ref="peopleSectionTitle"
            v-model="campaign.peopleSectionTitle"
            :rules="[rules.required]"
            :disabled="hasSubmitIntention"
            label="Title*"
            clearable
            required
            placeholder="Enter the title for people section"
          />
          <select-users
            v-if="setPeople && allUsers"
            ref="campaignPeople"
            v-model="selectedPeople"
            :label="`Select ${campaign.peopleSectionTitle || ' users'}`"
            :source="allUsers"
            :rules="[rules.required, rules.atLeastOneItem]"
          />
          <v-checkbox
            v-model="campaign.hideProjects"
            v-ruid="'hideCampaignProjectsCheck'"
            :disabled="hasSubmitIntention"
            label="Hide projects for this campaign"
          />
          <div class="my-6">
            <app-button
              cancel
              secondary
              :ruid="`cancelSaveCampaignButton`"
              @click="cancel"
            >
              CANCEL
            </app-button>
            <app-button
              :ruid="`saveCampaignButton`"
              :disabled="hasSubmitIntention"
              class="ml-4"
              @click="processCampaign"
            >
              SAVE
            </app-button>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import moment from 'moment';
import { Editor, ValidateMixin, SelectImage, SelectUsers } from '@kickbox/common-admin';
import { VideoDisplay } from '@kickbox/common-components';
import fileService from '@kickbox/common-util/service/fileService';
import VideoUpload from '@/components/widgets/VideoUpload';
import UserService from '@/services/userService';
import CampaignService from '@/services/campaignService';
import DatePicker from '@/components/dialogs/DatePicker';

export default {
  components: {
    Editor,
    VideoUpload,
    SelectUsers,
    VideoDisplay,
    SelectImage,
    DatePicker
  },
  mixins: [ValidateMixin],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      fromExternal: false,

      newCampaignId: null,
      campaign: {},
      activeTabIndex: 0,
      enabledLanguages: [],

      setDeadline: false,
      campaignDeadline: null,
      startDate: null,
      validDate: true,

      setPeople: false,
      selectedPeople: null,
      allUsers: null,

      hasSubmitIntention: false,
      submittedThroughVideo: false,
      videoUploadDetails: {},
      showDescriptionValidationHint: {}
    };
  },
  computed: {
    ...mapGetters([
      'campaignById',
      'campaigns',
      'company'
    ]),
    storageKey() {
      return this.id ? `videos/campaign-${this.id}` : `videos/new-campaign-${uuidv4()}`;
    },
    objectInfo() {
      return {
        type: 'Campaign',
        id: this.id || this.newCampaignId
      };
    },
    coverUploader() {
      return this.$refs.selectImage.image;
    }
  },
  watch: {
    setDeadline() {
      if (this.setDeadline) {
        this.campaignDeadline = this.campaignDeadline ? this.campaignDeadline : this.startDate;
      } else {
        this.campaignDeadline = null;
      }
    }
  },
  async created() {
    // =========================================================================
    // Sometimes the EditCampaign UI loads by id before the list of campaigns are resolved
    // Depends on network speed too
    // Thats why I am making a fetch just to be safe.
    // =========================================================================
    if (this.campaignById(this.id)) {
      this.campaign = this.campaignById(this.id);
    } else {
      this.campaign = this.id
        ? await CampaignService.fetchCampaignById(this.id)
        : this.createNewCampaignObj();
    }

    // =========================================================================
    // Get the current company's language for the tabs
    // =========================================================================
    this.enabledLanguages = this.company.enabledLanguages.sort();
    const {
      deadline, people, startDate
    } = this.campaign;
    // =========================================================================
    // What kind of stupid datepicker works with date strings and not date objects
    // =========================================================================
    this.setDeadline = !!deadline;
    this.campaignDeadline = this.setDeadline ? moment(deadline).format('YYYY-MM-DD') : null;
    this.startDate = moment(startDate).format('YYYY-MM-DD');

    this.setPeople = people && !!people.length;
    this.selectedPeople = this.setPeople ? people : [];

    this.enabledLanguages.forEach((_) => {
      this.videoUploadDetails[_] = {
        videoError: null,
        totalSegments: 0,
        uploadedSegments: 0
      };
      if (this.campaign.content && !this.campaign.content.hasOwnProperty(_)) {
        this.campaign.content[_] = {
          title: '',
          tagline: '',
          description: '',
          video: ''
        };
      }
    });
    this.gotoDefaultVideo();
    // =========================================================================
    // Get the users to show for people
    // This takes around 2 sec. Right now we do fetch the users for the Users UI
    // on page load. So I can fetch it here from the store. But when we are going
    // to fix it we will have to fetch the users here anyway.
    // =========================================================================
    this.allUsers = await UserService.getUsersPerCompany();
  },
  mounted() {
    window.addEventListener('beforeunload', (e) => {
      const message = 'Upload is in progress... Do you want to cancel it?';
      // eslint-disable-next-line no-alert
      if (this.uploadInProgress() && !window.confirm(message)) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromExternal = from.fullPath === '/';
    });
  },
  beforeRouteLeave(to, from, next) {
    const message = 'Upload is in progress... Do you want to cancel it?';
    if (this.uploadInProgress()) {
      // eslint-disable-next-line no-alert
      if (!window.confirm(message)) {
        next(false);
        return;
      }

      this.hasSubmitIntention = false;

      if (this.getVideoComponents().length) {
        // always null check to prevent error on page refresh
        this.getVideoComponents().forEach((_) => _.cancelUpload());
      }
    }
    next();
  },
  methods: {
    isDefault(lang) {
      return this.campaign.defaultLanguage === lang;
    },
    uploadInProgress() {
      return Object.values(this.videoUploadDetails)
        .some((_) => !!_.totalSegments && _.uploadedSegments < _.totalSegments);
    },
    createNewCampaignObj() {
      const content = {};
      // eslint-disable-next-line no-return-assign
      this.enabledLanguages.forEach((_) => content[_] = {});
      return {
        cover: null,
        content,
        deadline: null,
        peopleSectionTitle: null,
        people: null
      };
    },
    videoChangeEvent(event, lang) {
      const {
        content
      } = this.campaign;

      content[lang].video = event;
      if (this.isDefault(lang) && (!event || !event.length)) {
        this.campaign.defaultLanguage = null;
        Object.keys(content).forEach((_) => {
          content[_].video = null;
        });
      }
      this.$forceUpdate();
    },
    getVideoComponents(langKey) {
      const components = [];
      const base = 'videoUpload';
      // ==============================================================
      // If multiple components rendered under a v-for loop and they
      // have a "ref", this.$refs[ref] returns an array. Even though I managed
      // to make the "ref" dynamic and unique (see :ref in video-upload component),
      // this.$refs[ref] is still returning an array with one item.
      // ==============================================================
      if (langKey) {
        const name = `${base}${langKey}`;
        return this.$refs.hasOwnProperty(name) ? this.$refs[name][0] : null;
      }
      this.enabledLanguages.forEach((lang) => {
        const name = `${base}${lang}`;
        if (this.$refs.hasOwnProperty(name) && this.$refs[name].length) {
          components.push(this.$refs[name][0]);
        }
      });
      return components;
    },
    makeVideoDefault(langKey) {
      const {
        content, defaultLanguage: def
      } = this.campaign;
      const details = this.videoUploadDetails;

      this.enabledLanguages.forEach((lang) => {
        if (lang !== langKey) {
          content[lang].video = (def && content[def].video) || null;
          details[lang].videoError = (def && details[def].videoError) || null;
        }
      });
    },
    getStorageKeyForDisplay(lang) {
      return `${this.storageKey}_${this.campaign.defaultLanguage || lang}`;
    },
    gotoDefaultVideo() {
      this.activeTabIndex = this.enabledLanguages
        .findIndex((_) => this.campaign.defaultLanguage === _);
    },
    validateForm() {
      // ==============================================================
      // Validate 'title', 'tagline', 'description' for all languages
      // ==============================================================
      let formIsValid = true;
      const missingLanguages = this.enabledLanguages.filter((lang) => {
        const {
          title,
          tagline,
          description
        } = this.campaign.content[lang];
        return !title || !tagline || !description;
      });
      if (missingLanguages.length) {
        formIsValid = false;
        this.$store.dispatch('showSnackBar', {
          text: `Please, fill in Title, Tagline & Description for: ${missingLanguages.join(', ')}`
        });
      }

      ['title', 'tagline', 'description'].forEach((ctrl) => {
        this.$refs[ctrl].forEach((comp) => {
          const result = comp.validate(true);
          if (ctrl === 'description') {
            // change this line if the description ruid change
            const lang = comp.$attrs.ruid.split('_')[1];
            this.showDescriptionValidationHint[lang] = !result;
          }
        });
      });
      // ==============================================================
      // Validate 'videoUpload' component for all languages or the default one
      // ==============================================================
      this.getVideoComponents().forEach((_) => _.validate());
      const invalidUpload = Object.values(this.videoUploadDetails).find((_) => !!_.videoError);
      if (formIsValid && invalidUpload) {
        this.$store.dispatch('showSnackBar', {
          text: 'Please, upload default videos or all videos.'
        });
        formIsValid = false;
      }
      // ==============================================================
      // If set deadline, check if date was selected
      // ==============================================================
      if (this.setDeadline && !this.campaignDeadline && formIsValid) {
        this.$store.dispatch('showSnackBar', {
          text: 'Please, select the date for dead line.'
        });
        formIsValid = false;
      }
      // =============================================================================
      // If setDeadline is set, the startDate cannot be greater than campaignDeadline
      // =============================================================================
      if (this.setDeadline && this.campaignDeadline) {
        const diff = moment(this.startDate).diff(this.campaignDeadline, 'days');
        if (diff > 0) {
          this.validDate = false;
          this.$store.dispatch('showSnackBar', {
            text: 'The start date cannot be grater than the deadline'
          });
          formIsValid = false;
        } else {
          this.validDate = true;
        }
      }
      // ==============================================================
      // If jury is set, validate the user selection
      // ==============================================================
      ['peopleSectionTitle', 'campaignPeople'].forEach((ctrl) => {
        const result = this.setPeople ? this.$refs[ctrl].validate(true) : true;
        if (formIsValid && !result) {
          this.$store.dispatch('showSnackBar', {
            text: 'Please, fill in all the mandatory fields.'
          });
          formIsValid = false;
        }
      });
      return formIsValid;
    },
    processVideos(campaignId) {
      const defaultKey = this.campaign.defaultLanguage;
      if (this.campaign.defaultLanguage) {
        const saveVideo = campaignId
          ? this.getVideoComponents(defaultKey).save(`videos/campaign-${campaignId}_${defaultKey}`)
          : this.getVideoComponents(defaultKey).save();
        return saveVideo.then((videoLink) => {
          this.enabledLanguages.forEach((_) => {
            if (this.campaign.content.hasOwnProperty(_)) {
              this.campaign.content[_].video = videoLink;
            }
          });
          return this.campaign.content;
        });
      }
      const pendingVideos = [];
      this.enabledLanguages.forEach(async (lang) => {
        const component = this.getVideoComponents(lang);
        if (component) {
          const saveVideo = campaignId
            ? component.save(`videos/campaign-${campaignId}_${lang}`)
            : component.save();
          pendingVideos.push(
            saveVideo.then((videoLink) => {
              this.campaign.content[lang].video = videoLink;
              return this.campaign.content;
            })
          );
        }
      });
      return Promise.all(pendingVideos);
    },
    processCoverPhoto(id) {
      if (!this.coverUploader.hasImage()) {
        return null;
      }
      if (!this.coverUploader.currentIsInitial && this.coverUploader.hasImage()) {
        const objectInfo = {
          type: 'Campaign',
          id,
          field: 'cover'
        };
        return fileService
          .uploadImage(this.coverUploader, this.campaign.cover, 'public-read', objectInfo);
      }
      return this.campaign.cover;
    },
    processCampaign() {
      if (!this.validateForm()) {
        return;
      }
      this.hasSubmitIntention = true;
      if (!this.uploadInProgress()) {
        this.submit();
      } else {
        this.$store.dispatch('showSnackBar', {
          text: 'Videos are still uploading'
        });
      }
    },
    async submit() {
      // in catch block, this.$refs.videoUpload is undefined
      const uploadErrorMessage = this.getVideoComponents()
        .map((_) => _.getUploadCancelledErrorMessage());

      try {
        let cover;
        if (this.id) {
          await this.processVideos(this.id);
          cover = await this.processCoverPhoto(this.id);
        }

        const people = this.setPeople && this.selectedPeople && this.selectedPeople.length
          ? this.selectedPeople
          : null;
        const startDate = moment(this.startDate).endOf('day').toDate();
        const deadline = this.setDeadline
          ? moment(this.campaignDeadline).endOf('day').toDate()
          : null;
        const {
          content, defaultLanguage, peopleSectionTitle, hideProjects, disabled
        } = this.campaign;

        const newCampaign = {
          id: this.id,
          cover,
          content,
          defaultLanguage,
          peopleSectionTitle: this.setPeople ? peopleSectionTitle : null,
          startDate,
          deadline,
          people,
          hideProjects,
          disabled
        };
        const campaign = await CampaignService.saveCampaign(newCampaign);

        if (!this.id) {
          this.newCampaignId = campaign.id;
          campaign.cover = await this.processCoverPhoto(campaign.id);
          await this.processVideos(campaign.id);
          campaign.content = newCampaign.content;
          await CampaignService.saveCampaign(campaign);
        }
        if (this.fromExternal) {
          this.$router.push({ name: 'AdminManageCampaigns' });
        } else {
          this.$router.go(-1);
        }
      } catch (error) {
        const errMsg = uploadErrorMessage.find((_) => _ !== error.message);
        if (errMsg) {
          throw error;
        }
      }
    },
    cancel() {
      if (this.fromExternal) {
        this.$router.push({ name: 'AdminManageCampaigns' });
      } else {
        this.$router.go(-1);
      }
    },
    onProgress(event, lang) {
      this.videoUploadDetails[lang].totalSegments = event.segments;
      this.videoUploadDetails[lang].uploadedSegments = event.uploaded;
      if (!this.uploadInProgress() && !this.submittedThroughVideo && this.hasSubmitIntention) {
        this.submittedThroughVideo = true;
        this.submit();
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .campaign-container {
    padding: 25px;

    .image-uploader-container {
      margin-bottom: 25px;

      label, p {
        display: block;
        font-weight: 500;
        margin: 10px 0;
      }
    }

    .tabs-container {
      .container {
        padding: 24px 0;
      }

      .video-uploading {
        margin-left: 15px;
      }
    }

    .default-video-defined {
      display: inline-flex;
      background-color: #e7e7e7;
      padding: 10px;
      width: 100%;
      border-radius: 5px;
      align-content: center;
      align-items: center;
      cursor: pointer;

      .default-video-label {
        margin-left: 10px;
      }
    }
  }

</style>

<style scoped lang="scss">
  ::v-deep .ql-toolbar.ql-snow + .ql-container.ql-snow {
    /* display top border when disabled */
    border-top: 1px solid #ccc;
  }

  label {
    color: rgba(0, 0, 0, .6);
    font-size: 12px;
  }
</style>
